<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template  v-slot:headerTitle>
            <h4 v-if="$route.query.id" class="card-title">{{ $t('pest_management.disease') + ' ' + $t('globalTrans.update') }}</h4>
            <h4 v-else class="card-title">{{ $t('pest_management.disease') + ' ' + $t('globalTrans.entry') }}</h4>
         </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="disease.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :disabled="disease.org_id != ''"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('VirtualMuseum.configuration.crop_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="disease.crop_type_id"
                                            :options="cropTypeList"
                                            id="crop_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Crop" vid="crop_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.crop')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="disease.crop_id"
                                            :options="cropList"
                                            id="crop_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col>
                                    <ValidationProvider name="Pest Name" vid="pest_name_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="pest_name_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('pest_management.pest_name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="disease.pest_name"
                                            :options="pestNameList"
                                            id="pest_name_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="disease Name (En)" vid="name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.disease_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="name"
                                            v-model="disease.name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="disease Name (Bn)" vid="name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="designation"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.disease_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="designation"
                                            v-model="disease.name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <!-- =====================New Start================== -->
                            <!-- Description start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.basic_description')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Basic description" vid="basic_desc" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="basic_desc"
                                                v-model="disease.basic_desc"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Basic description bn" vid="basic_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="basic_desc_bn"
                                                v-model="disease.basic_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:25%;">
                                            <ValidationProvider name="Basic Desc Photo" vid="basic_desc_photo">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            style="margin:10px 0px 10px -15px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="basic_desc_photo"
                                                @change="basicDescFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="basic_desc_photo_src" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="basic_desc_photo_src"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="basic_desc_photo_src"
                                                    v-model="disease.basic_desc_photo_src"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.basic_desc_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.basic_desc_photo" />
                                            <b-img v-else style="mareffectgin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" alt="profile-pic" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Description end -->
                            <!-- =====================New End==================== -->

                            <!-- Life cycle start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.life_cycle')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Life cycle description" vid="life_cycle_desc" rules="">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-textarea
                                                id="life_cycle_desc"
                                                v-model="disease.life_cycle_desc"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Life cycle description bn" vid="life_cycle_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="life_cycle_desc_bn"
                                                v-model="disease.life_cycle_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:25%">
                                            <ValidationProvider name="life cycle photo" vid="life_cycle_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="life_cycle_photo"
                                                @change="lifeCycleFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="life_cycle_photo_src" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="life_cycle_photo_src"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="life_cycle_photo_src"
                                            v-model="disease.life_cycle_photo_src"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.life_cycle_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.life_cycle_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" alt="profile-pic" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Life cycle end -->

                             <!-- Destructive start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.destructive_stage')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Destructive description" vid="destructive_desc" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="destructive_desc"
                                                v-model="disease.destructive_desc"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Destructive description bn" vid="destructive_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="destructive_desc_bn"
                                                v-model="disease.destructive_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:25%">
                                            <ValidationProvider name="destructive photo" vid="destructive_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="destructive_photo"
                                                @change="destructiveFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="destructive_photo_src" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="destructive_photo_src"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="destructive_photo_src"
                                            v-model="disease.destructive_photo_src"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                        </b-td>
                                         <b-td style="width:15%">
                                            <b-img v-if="disease.destructive_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.destructive_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                     </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Destructive End -->

                            <!-- Attacking parts start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.attacking_parts')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Attacking parts" vid="attacking_parts_desc" rules="">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                >
                                                <b-form-textarea
                                                id="attacking_parts_desc"
                                                v-model="disease.attacking_parts_desc"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                ></b-form-textarea>
                                                 <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Attacking description bn" vid="attacking_parts_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="attacking_parts_desc_bn"
                                                v-model="disease.attacking_parts_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:25%">
                                            <ValidationProvider name="attacking part photo" vid="attacking_parts_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="attacking_parts_photo"
                                                @change="attackingPartsFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="attacking_parts_photo_src" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="attacking_parts_photo_src"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="attacking_parts_photo_src"
                                            v-model="disease.attacking_parts_photo_src"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.attacking_parts_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.attacking_parts_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                     </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Attacking parts end -->
                                                        <!-- Symptom start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.symptom')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Symptom desc" vid="symptom_desc" rules="">
                                                <b-form-group
                                                slot-scope="{ valid, errors }"
                                                >
                                                    <b-form-textarea
                                                    id="symptom_desc"
                                                    v-model="disease.symptom_desc"
                                                    cols="40"
                                                    rows="4"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    style="margin:10px 0px 10px 0px"
                                                    ></b-form-textarea>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Symptom description bn" vid="symptom_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="symptom_desc_bn"
                                                v-model="disease.symptom_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                         <b-td style="width:25%">
                                            <ValidationProvider name="symptom photo" vid="symptom_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="symptom_photo"
                                                @change="symptomFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="symptom_photo_src" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="symptom_photo_src"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="symptom_photo_src"
                                                    v-model="disease.symptom_photo_src"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.symptom_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.symptom_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Symptom end -->

                            <!-- Effect on start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.effect_on_plant')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                </b-tbody>
                                <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Effect plant description" vid="effect_plant_desc" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="effect_plant_desc"
                                                v-model="disease.effect_plant_desc"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                ></b-form-textarea>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Effect description bn" vid="effect_plant_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="effect_plant_desc_bn"
                                                v-model="disease.effect_plant_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    Maximum limit is 500 character
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                         <b-td style="width:25%">
                                            <ValidationProvider name="effect plant photo" vid="effect_plant_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="effect_plant_photo"
                                                @change="effectPlantFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                <div class="cust-feedback">
                                                    Please Upload png,gif,jpeg,jpg image
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="effect_plant_photo_src" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="effect_plant_photo_src"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="effect_plant_photo_src"
                                                    v-model="disease.effect_plant_photo_src"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.effect_plant_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.effect_plant_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                </b-tr>
                            </b-table-simple>
                            <!-- Effect on end -->

                            <!-- Management start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.management')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_en')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description_bn')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.image')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Management desc" vid="management_desc" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="management_desc"
                                                v-model="disease.management_desc"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:30%; height: 50px !important">
                                            <ValidationProvider name="Management description bn" vid="management_desc_bn" rules="">
                                            <b-form-group
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-textarea
                                                id="management_desc_bn"
                                                v-model="disease.management_desc_bn"
                                                cols="40"
                                                rows="4"
                                                style="margin:10px 0px 10px 0px"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-textarea>
                                                <div class="invalid-feedback">
                                                    <!-- Maximum limit is 500 character -->
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                         <b-td style="width:25%">
                                            <ValidationProvider name="management photo" vid="management_photo">
                                            <b-form-group
                                            class="row"
                                            style="margin:10px 0px 10px 10px"
                                            slot-scope="{ valid, errors }"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="management_photo"
                                                @change="managementFileChange"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                    <div class="cust-feedback">
                                                        Please Upload png,gif,jpeg,jpg image
                                                    </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                            <br/>
                                            <ValidationProvider name="Image Source" vid="management_photo_src" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="management_photo_src"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="management_photo_src"
                                                    v-model="disease.management_photo_src"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-td>
                                        <b-td style="width:15%">
                                            <b-img v-if="disease.management_photo" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="disease.management_photo" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1">
                                        <router-link :class="'text-light'" :to="{ path: '/virtual-museum-service/pest-management/manage-disease'}" size="sm">{{ $t('globalTrans.cancel') }}
                                        </router-link>
                                    </b-button>
                                </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scope>
    .cust-feedback{
        width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { diseaseManageStoreApi, diseaseManageDetailsApi, diseaseManageUpdateApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      this.disease.org_id = this.$store.state.dataFilters.orgId
    if (this.$route.query.id) {
      const tmp = this.getDetails()
      this.disease = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.$route.query.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        cropList: [],
        disease: {
            org_id: 0,
            name: '',
            name_bn: '',
            pest_name: 0,
            crop_id: '0',
            crop_type_id: '0',
            basic_desc: '',
            basic_desc_bn: '',
            basic_desc_photo: '',
            basic_desc_photo_src: '',
            life_cycle_desc: '',
            life_cycle_desc_bn: '',
            life_cycle_photo: '',
            life_cycle_photo_src: '',
            destructive_desc: '',
            destructive_desc_bn: '',
            destructive_photo: '',
            destructive_photo_src: '',
            attacking_parts_desc: '',
            attacking_parts_desc_bn: '',
            attacking_parts_photo: '',
            attacking_parts_photo_src: '',
            effect_plant_desc: '',
            effect_plant_desc_bn: '',
            effect_plant_photo: '',
            effect_plant_photo_src: '',
            symptom_desc: '',
            symptom_desc_bn: '',
            symptom_photo: '',
            symptom_photo_src: '',
            management_desc: '',
            management_desc_bn: '',
            management_photo: '',
            management_photo_src: '',
            basic_desc_photo_edit: 0,
            life_cycle_photo_edit: 0,
            destructive_photo_edit: 0,
            attacking_parts_photo_edit: 0,
            effect_plant_photo_edit: 0,
            symptom_photo_edit: 0,
            management_photo_edit: 0
        },
        defaultImage: require('../../../../../assets/images/no-image.png')

    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      pestNameList: function () {
          const pestData = this.$store.state.VirtualMuseum.commonObj.pestList
          return pestData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      cropTypeList: function () {
            const cropTypeData = this.$store.state.VirtualMuseum.commonObj.cropTypeList.filter(item => item.status === 1)
            return cropTypeData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
  },
  watch: {
    'disease.crop_type_id': function (newVal, oldVal) {
        this.cropList = this.getCropList(newVal)
    }
  },
  methods: {
    async getDetails () {
      this.loadingState = true
      const result = await RestApi.getData(virtualMuseumServiceBaseUrl, diseaseManageDetailsApi + '/' + this.$route.query.id)
      if (result.success) {
            this.disease = result.data
            const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === this.disease.org_id)
            const cropType = this.$store.state.VirtualMuseum.commonObj.cropTypeList.find(type => type.value === this.disease.crop_type_id)
            const crop = this.$store.state.VirtualMuseum.commonObj.cropList.find(crop => crop.value === this.disease.crop_id)
            this.organization_name = this.$i18n.locale === 'bn' ? org.text_bn : org.text_en
            this.crop_type = this.$i18n.locale === 'bn' ? cropType.text_bn : cropType.text_en
            this.crop_name = this.$i18n.locale === 'bn' ? crop.text_bn : crop.text_en
            this.disease.pest_name = this.disease.pest_id
            this.disease.basic_desc_photo = this.disease.basic_desc_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.basic_desc_photo : ''
            this.disease.life_cycle_photo = this.disease.life_cycle_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.life_cycle_photo : ''
            this.disease.destructive_photo = this.disease.destructive_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.destructive_photo : ''
            this.disease.attacking_parts_photo = this.disease.attacking_parts_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.attacking_parts_photo : ''
            this.disease.effect_plant_photo = this.disease.effect_plant_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.effect_plant_photo : ''
            this.disease.symptom_photo = this.disease.symptom_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.symptom_photo : ''
            this.disease.management_photo = this.disease.management_photo !== null ? virtualMuseumServiceBaseUrl + '/pest-management/' + this.disease.management_photo : ''
        }
        this.loadingState = false
     },
     getCropList () {
        const cropData = this.$store.state.VirtualMuseum.commonObj.cropList.filter(item => item.type_id === this.disease.crop_type_id)
        return cropData
    },
    basicDescFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.basic_desc_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.basic_desc_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.basic_desc_photo_edit = 1
        }
    },
    lifeCycleFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.life_cycle_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.life_cycle_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.life_cycle_photo_edit = 1
        }
    },
    destructiveFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.destructive_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.destructive_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.destructive_photo_edit = 1
        }
    },
    // lifeCycleFileChange (e) {
    //   this.life_cycle_photo = e.target.files[0]
    //   this.life_cycle_img_pr = URL.createObjectURL(this.life_cycle_photo)
    // },
    attackingPartsFileChange (event) {
       const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.attacking_parts_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.attacking_parts_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.attacking_parts_photo_edit = 1
        }
    },
    effectPlantFileChange (event) {
      const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.effect_plant_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.effect_plant_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.effect_plant_photo_edit = 1
        }
    },
    symptomFileChange (event) {
      const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.symptom_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.symptom_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.symptom_photo_edit = 1
        }
    },
    managementFileChange (event) {
      const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.disease.management_photo = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.disease.management_photo = ''
        }
        if (this.$route.query.id) {
            this.disease.management_photo_edit = 1
        }
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(
            virtualMuseumServiceBaseUrl,
            `${diseaseManageUpdateApi}/${this.$route.query.id}`,
            this.disease
            )
       } else {
        result = await RestApi.postData(virtualMuseumServiceBaseUrl, diseaseManageStoreApi, this.disease)
       }
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.$route.query.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$router.push('manage-disease')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
      this.loadingState = false
    }
  }
}
</script>
